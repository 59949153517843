<template>
  <ActivitiesList
    :adult_activities="adult_acts"
    :child_activities="child_acts"
  />
</template>

<script>
import ActivitiesList from "../components/activities/ActivitiesList.vue"
import { add } from "date-fns"
import gql from "graphql-tag"

const getActivities = gql `
    query Activities($activityDay: String!) {
        adult_acts: activities(limit: -1, sort: "date:asc", where: { date_gt: $activityDay, programme: "Adults"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_no
            contentShort_en
            content_no
            content_en
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                id
                date
            }
        }
        child_acts: activities(limit: -1, sort: "date:asc", where: { date_gt: $activityDay, programme: "Children"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_en
            contentShort_no
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                id
                date
            }
        }
    }
`;

export default {
    apollo: {
        adult_acts: {
            query: getActivities,
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        },
        child_acts: {
            query: getActivities,
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        }
    },
    components: {
        ActivitiesList
    },
    data: function() {
        return {
            adult_acts: [],
            child_acts: [],
            activityDay: ''
        };
    },
    computed: {

        sortAdults() {
            return this.processEvents(this.adult_acts)
        },
        sortChildren() {
            return this.processEvents(this.child_acts)
        }
    },
    created() {
        this.getActivityDay()
    },
    methods: {
        getActivityDay() {
            let date = new Date();
            let datePlusDay = add(date, {days: -1}) 
            this.activityDay = datePlusDay
        },
        processEvents(array) {
            let today = new Date();
            let yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1);

            let activities = array.map((e) => {
                function filterDate(item) {
                    let thisDate = new Date(item.date)
                    if (thisDate > yesterday) {
                        return true
                    }
                    return false;
                }
                let result = e.planned_events.filter(filterDate)
                e.planned_events = result
                return e
            })

            let sorted = activities.sort((a, b) => {
                return (new Date(a.planned_events[0].date) - new Date(b.planned_events[0].date));
            })

            return sorted;
        },
    },
    metaInfo() {
        return { 
            title: this.$t("nav.activities"),
        }
    },
};
</script>
