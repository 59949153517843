<template>
  <section v-if="activities" id="single-activity">
    <BaseNotificationMessage v-if="activities[0] && checkEN && $i18n.locale === 'en'" :static-message-e-n="staticMessageEN" :message-type="messageType"/>
    
    <div class="defaultcontent-grid">
      <PhotoCover :photo-cover="activities[0].photo_cover" />
    
      <div class="singlepage-grid-primary">
        <div class="activity-meta metatext">
          <p v-if="activities[0].activity_category">
            <span v-if="$i18n.locale === 'nb'">{{ activities[0].activity_category.category_no }}</span>
            <span v-else>{{ activities[0].activity_category.category_en }}</span>
          </p>
        </div>
    
        <p v-if="$i18n.locale === 'nb'" id="activity-title" class="title-default">
          {{ activities[0].title_no }}
        </p>
        <p v-else id="activity-title" class="title-default">
          {{ activities[0].title_en }}
        </p>
    
        <div v-if="activities[0].content_no" class="activity-description">
          <MarkedParser v-if="$i18n.locale === 'nb'" class="markdown" :marked-content="activities[0].content_no" />
          <MarkedParser v-else-if="activities[0].content_en === '' || activities[0].content_en === null" class="markdown" :marked-content="activities[0].content_no" />
          <MarkedParser v-else class="markdown" :marked-content="activities[0].content_en" />
        </div>

        
        <div v-if="activities[0].planned_events.length > 0" class="activity-dates">

          <div v-for="item in showComingPlanned" :key="item.id" class="activity-date-block" :class="[ item.url ? 'card-hover' : '', eventPassed(item.date) ? 'passed' : '']">
            <a v-if="item.url" :href="'https://' + item.url" target="_blank" rel="noreferrer noopener">
              <p v-if="$i18n.locale === 'nb'" class="day">{{ item.date | formatNBday }}</p>
              <p v-else class="day">{{ item.date | formatENday }}</p>
              <p class="date">{{ item.date | formatDateFull }}<span><svgExternal /></span></p>
              <p>{{ item.time_start | formatTime }}–{{ item.time_end | formatTime }}</p>
            </a>
            <div v-else>
              <p v-if="$i18n.locale === 'nb'" class="day">{{ item.date | formatNBday }}</p>
              <p v-else class="day">{{ item.date | formatENday }}</p>
              <p class="date">{{ item.date | formatDateFull }}</p>
              <p>{{ item.time_start | formatTime }}–{{ item.time_end | formatTime }}</p>
            </div>
          </div>

          <div v-for="item in showPastPlanned" :key="item.id" class="activity-date-block" :class="[ item.url ? 'card-hover' : '', eventPassed(item.date) ? 'passed' : '']">
            <a v-if="item.url" :href="'https://' + item.url" target="_blank" rel="noreferrer noopener">
              <p v-if="$i18n.locale === 'nb'" class="day">{{ item.date | formatNBday }}</p>
              <p v-else class="day">{{ item.date | formatENday }}</p>
              <p class="date">{{ item.date | formatDateFull }}<span><svgExternal /></span></p>
              <p>{{ item.time_start | formatTime }}–{{ item.time_end | formatTime }}</p>
            </a>
            <div v-else>
              <p v-if="$i18n.locale === 'nb'" class="day">{{ item.date | formatNBday }}</p>
              <p v-else class="day">{{ item.date | formatENday }}</p>
              <p class="date">{{ item.date | formatDateFull }}</p>
              <p>{{ item.time_start | formatTime }}–{{ item.time_end | formatTime }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
    

    <BaseBtnBack />
  </section>
</template>

<script>
import PhotoCover from '@/components/PhotoCover'
import BaseBtnBack from '@/components/base/BaseBtnBack'
import MarkedParser from '@/components/MarkedParser'
import svgExternal from '@/components/base/svgExternal'

import gql from 'graphql-tag'
import { format } from 'date-fns'
import LazyLoad from 'vanilla-lazyload'

const getActivities = gql `
    query Activities ($slug: String!) {
        activities(limit: 1, where: { slug: $slug }) {
            slug
            title_en
            title_no
            photo_cover {
                formats
                caption
            }
            activity_category {
                category_no
                category_en
            }
            planned_events {
                date
                time_start
                time_end
                url
            }
            content_no
            content_en
        }
    }
`;

export default {
    apollo: {
        activities: {
            query: getActivities,
            variables() {
                return {
                    slug: this.$route.params.slug
                };
            },
            update: data => data.activities
        }
    },
    components: {
        PhotoCover,
        BaseBtnBack,
        MarkedParser,
        svgExternal
    },
    data() {
        return {
            ogTitle: '',
            ogDesc: '',
            ogImage: '',
            staticMessageNO: 'Denne siden er ikke tilgjengelig på engelsk',
            staticMessageEN: 'This page is not available in English',
            messageType: 'error',
            post: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    metaInfo() {
        return {
            title: this.ogTitle,
            meta: [
                { property: 'og:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: this.api_url + this.ogImage, vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: this.api_url + this.ogImage, vmid: 'twitter:card' },
                { property: 'twitter:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    computed: {
        showPastPlanned () {
          return this.checkPast(this.activities[0].planned_events)
        },
        showComingPlanned () {
          return this.checkComing(this.activities[0].planned_events)
        },
        today() {
            return format(new Date(), "dd.MM.yyyy HH:mm")
        },
        checkRegistration() {
            let thisDay = new Date()
            let actClosed = new Date(this.activities[0].reg_closed);
            let actStart = new Date(this.activities[0].date);
            if (thisDay < actClosed) {
                return true;
            } else if (thisDay > actStart) {
                return this.coming
            } else {
                return this.past
            }
        },
        checkEN: function() {
            let activity = this.activities[0];
            let textEN = activity.content_en
            if (textEN === '' || textEN === null) {
                return true
            }
            return false
        },
    },
    mounted() {
        this.activityLazyLoad = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 100,
        });
    },
    updated() {
        let activityTitle = this.activities[0].title_no;
        let activityDescription = this.activities[0].content_no;
        let activityCover = this.activities[0].photo_cover.formats.small.url;

        if (activityTitle) {
            this.ogTitle = activityTitle
        }
        if (activityDescription) {
            this.ogDesc = activityDescription
        }
        if (activityCover) {
            this.ogImage = activityCover
        }

        this.activityLazyLoad.update();
    },
    methods: {
      checkContent(content, time) {
        let planned_events = content
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (time === 'past') {
          function filterTime(item) {
            let finalDate = new Date(item.date)
            if (finalDate < yesterday) {
              return true
            } 
            return false;
          }
          return planned_events.filter(filterTime)
        } else if (time === 'coming') {
          function filterTime(item) {
            let finalDate = new Date(item.date)
            if (finalDate > yesterday) {
              return true
            } 
            return false;
          }
          return planned_events.filter(filterTime)
        }
      },
      checkComing (content) {
        return this.checkContent(content, 'coming')
        return content
      },
      checkPast (content) {
        return this.checkContent(content, 'past')
      },
        eventPassed(date) {
            let eventDate = new Date(date)
            let today = new Date();
            let yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1);
            if (eventDate < yesterday) {
                return true
            }
            return false
        },
    }
};
</script>
