<template>
    <ul>
        <li v-for="item in listContent" id="item-bar" :key="item.slug" class="bar">

            <!-- {{ item.__typename }} -->

            <div id="item-preview--header" class="bar-grid">
                <div class="bar-grid--left">
                    <p v-if="item.date_end">
                        <span>{{ item.date_end | formatDateYear }}</span>
                    </p>
                    <p v-if="item.__typename == 'Post' || item.__typename == 'Interview'">
                        <span>{{ item.manual_post_date | formatDateFull }}</span>
                    </p>
                    <p v-if="item.__typename == 'Activities'">
                        <span>{{ item.date | formatDateFull }}</span>
                    </p>
                    <p v-if="item.published_date">
                        <span>{{ item.published_date | formatDateFull }}</span>
                    </p>
                    <p v-if="item.__typename == 'ComponentLightactivitylistLightActivityList'">
                        <span v-if="item.date">{{ item.date | formatDateYear }}</span>
                        <span v-else-if="item.schoolyear">{{ item.schoolyear }}</span>
                    </p>
                </div>
    
                <div :class="[item.__typename == 'Post' || item.__typename == 'Activities' ? 'bar-grid--middle-newsfull' : 'bar-grid--middle']">
                    
                    <router-link v-if="item.__typename == 'Post'" :to="{ path: '/news/' + item.slug }">
                        <span v-if="$i18n.locale === 'en' && item.title_en !=''" class="title-hover">{{ item.title_en || item.title }}</span>
                        <span v-if="$i18n.locale === 'en' && item.title_en === ''" class="title-hover">{{ item.title_no || item.title }}</span>
                        <span v-else-if="$i18n.locale === 'nb'" class="title-hover">{{ item.title_no || item.title }}</span>
                    </router-link>
    
                    <router-link v-if="item.__typename == 'Exhibition'" :to="{ path: '/exhibitions/' + item.slug }">
                        <p v-if="$i18n.locale === 'en'" class="title-hover"><span>{{ item.title_en || item.title }}</span><span v-if="item.subtitle"> {{ item.subtitle }}</span></p>
                        <p v-else class="title-hover"><span>{{ item.title_no || item.title }}</span><span v-if="item.subtitle"> {{ item.subtitle }}</span></p>
                    </router-link>
    
                    <router-link v-if="item.__typename == 'Interview'" :to="{ path: '/nitja-online/' + item.slug }">
                        <span v-if="$i18n.locale === 'en'" class="title-hover">{{ item.title_en }}</span>
                        <span v-else class="title-hover">{{ item.title_no }}</span>
                    </router-link>

                    <router-link v-if="item.__typename == 'Activities'" :to="{ path: '/activities/' + item.slug }">
                        <span v-if="$i18n.locale === 'en'" class="title-hover">{{ item.title_en }}</span>
                        <span v-else class="title-hover">{{ item.title_no }}</span>
                    </router-link>
    
                    <p v-if="$i18n.locale === 'en' && item.url || item.file">
                        <a v-if="item.url" :href="item.url | cleanUrl" target="_blank" rel="noreferrer noopener" class="title-hover">{{ item.title_en }}</a>
                        <a v-else-if="item.file" :href="api_url + item.file.url" download class="title-hover">{{ item.title_en }}</a>
                    </p>
                    <p v-else-if="$i18n.locale === 'nb' && item.url || item.file">
                        <a v-if="item.url" :href="item.url | cleanUrl" target="_blank" rel="noreferrer noopener" class="title-hover">{{ item.title_no }}</a>
                        <a v-else-if="item.file" :href="api_url + item.file.url" download class="title-hover">{{ item.title_no }}</a>
                    </p>
    
                    <p v-if="item.type_no" class="title-hover">
                        <span v-if="$i18n.locale === 'en'" @click.self="togglePreview">{{ item.title_en }}</span>
                        <span v-else @click.self="togglePreview">{{ item.title_no }}</span>
                    </p>
                </div>
    
                <div :class="[item.__typename == 'Post' || item.__typename == 'Activities' ? 'bar-grid--right-newsfull' : 'bar-grid--right']">
                    <p v-if="item.author">
                        {{ item.author }}
                    </p>
                    <p v-if="item.type_no">
                        <span v-if="$i18n.locale === 'en' || ! item.type_en">{{ item.type_en }}</span>
                        <span v-else>{{ item.type_no }}</span>
                    </p>
                    <div v-if="item.artists && item.artists.length <= 10">
                        <p>
                            <span v-for="(artist, index) in item.artists" :key="artist.id" class="artist-name">
                                <span>{{ artist.first_name }} {{ artist.last_name }}</span>
                                <span v-if="index != item.artists.length - 1">, </span>
                                <span v-else-if="index == item.artists.length - 1 && item.artists.length > 1">.</span>
                            </span>
                        </p>
                    </div>
                    <p v-else-if="item.artists && item.artists.length >= 10">
                        {{ $t('exhibition.artistCounted') }} {{ item.artists.length }} {{ $t('exhibition.artistCounted2') }}
                    </p>
                </div>
    
                <div class="bar-grid--btn">
                    <a v-if="item.url" :href="item.url | cleanUrl" target="_blank" rel="noreferrer noopener"><i class="arrow right" /></a>
                    <a v-else-if="item.file" :href="api_url + item.file.url" download><i class="arrow right" /></a>
                    <router-link v-else-if="item.__typename == 'Interview'" :to="'/nitja-online/' + item.slug" download>
                        <i class="arrow right" />
                    </router-link>
                    <i v-else id="preview-toggler" class="arrow down" @click.self="togglePreview" />
                </div>
            </div>
    
            <div id="item-preview--content" class="bar-preview bar-grid">
                <div class="bar-grid--left" />
    
                <div class="bar-grid--middle">
                    <router-link v-if="item.date_end" :to="{ path: '/exhibitions/' + item.slug }">
                        <div id="item-thumbnail" class="thumbnail">
                            <img v-if="item.photo_cover && item.photo_cover.formats.thumbnail.width >= item.photo_cover.formats.thumbnail.height" class="thumb-landscape lazy" :data-src="api_url + item.photo_cover.formats.thumbnail.url" alt="">
    
                            <img v-else-if="item.photo_cover && item.photo_cover.formats.thumbnail.width <= item.photo_cover.formats.thumbnail.height" class="thumb-portrait lazy" :data-src="api_url + item.photo_cover.formats.thumbnail.url" alt="">
                        </div>
                    </router-link>
    
                    <router-link v-if="item.__typename == 'Post'" :to="{ path: '/news/' + item.slug }">
                        <div id="item-thumbnail" class="thumbnail">
                            <img v-if="item.photo_cover && item.photo_cover.formats.thumbnail.width >= item.photo_cover.formats.thumbnail.height" class="thumb-landscape lazy" :data-src="api_url + item.photo_cover.formats.thumbnail.url" alt="">
    
                            <img v-else-if="item.photo_cover && item.photo_cover.formats.thumbnail.width <= item.photo_cover.formats.thumbnail.height" class="thumb-portrait lazy" :data-src="api_url + item.photo_cover.formats.thumbnail.url" alt="">
                        </div>
                    </router-link>
    
                    <div v-if="item.__typename == 'Activities'" id="item-thumbnail" class="thumbnail">
                        <img v-if="item.photo_cover && item.photo_cover.formats.thumbnail.width >= item.photo_cover.formats.thumbnail.height" class="thumb-landscape lazy" :data-src="api_url + item.photo_cover.formats.thumbnail.url" alt="">
    
                        <img v-else-if="item.thumbnail && item.thumbnail.formats.thumbnail.width <= item.thumbnail.formats.thumbnail.height" class="thumb-portrait lazy" :data-src="api_url + item.thumbnail.formats.thumbnail.url" alt="">
                    </div>

                    <div v-if="item.thumbnail" id="item-thumbnail" class="thumbnail">
                        <img v-if="item.thumbnail && item.thumbnail.formats.thumbnail.width >= item.thumbnail.formats.thumbnail.height" class="thumb-landscape lazy" :data-src="api_url + item.thumbnail.formats.thumbnail.url" alt="">
    
                        <img v-else-if="item.thumbnail && item.thumbnail.formats.thumbnail.width <= item.thumbnail.formats.thumbnail.height" class="thumb-portrait lazy" :data-src="api_url + item.thumbnail.formats.thumbnail.url" alt="">
                    </div>
                </div>
    
                <div class="bar-grid--right">
                    <div v-if="item.contentShort_no || item.contentShort_en" id="list-content">
                        <MarkedParser v-if="$i18n.locale === 'nb'" class="markdown" :marked-content="item.contentShort_no" />
                        <MarkedParser v-else class="markdown" :marked-content="item.contentShort_en" />
    
                        <div v-if="item.related_exhibition" id="related-exhibition" class="metatext">
                            <p class="boldtext">
                                {{ $tc('posts.relatedExhibition', item.related_exhibition.length) }}
                            </p>
                            <router-link :to="{ path: '/exhibitions/' + item.related_exhibition.slug }" class="title-hover">
                                <span>{{ item.related_exhibition.title }}</span>
                            </router-link>
                        </div>

                    </div>
    
                    <router-link v-if="item.date_end && item.slug" :to="{ path: '/exhibitions/' + item.slug }" class="square-btn--default">
                        <span>{{ $t('buttons.viewExhibition') }}</span>
                    </router-link>

                    <router-link v-if="item.__typename =='Post'" :to="{ path: '/news/' + item.slug }" class="square-btn--default">
                        <span>{{ $t('buttons.viewPost') }}</span>
                    </router-link>

                    <router-link v-if="item.__typename == 'Activities'" :to="{ path: '/activities/' + item.slug }" class="square-btn--default">
                        <span>{{ $t('buttons.moreInfo') }}</span>
                    </router-link>

                </div>
    
                <div class="bar-grid--btn" />
            </div>
        </li>
    </ul>
</template>

<script>
import MarkedParser from '../components/MarkedParser'

import LazyLoad from 'vanilla-lazyload'

export default {
    components: {
        MarkedParser
    },
    props: {
        listContent: {
            type: Array,
            default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    },
    computed: {
        // checkEN: function() {
        //     let content = this.pageContent;
        //     let textEN = content.content_en
        //     if (textEN === '' || textEN === null) {
        //         return true
        //     }
        //     return false
        // }
    },
    mounted() {
        this.listLazyload = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 500,
            threshold: 100,
        });
    },
    methods: {
        checkDropdowns(toggler, dropdown) {
            const allDropdown = document.querySelectorAll('#item-preview--content')
            const allToggler = document.querySelectorAll('#preview-toggler')
            allDropdown.forEach(function(i) {
                if (i.classList.contains('opened')) {
                    i.classList.remove('opened');
                }
            })
            allToggler.forEach(function(i) {
                i.classList.remove('up');
                i.classList.add('down');
            })
            toggler.classList.remove('down');
            toggler.classList.add('up');
            dropdown.classList.add('opened');
        },
        togglePreview: function(event) {
            if (event) {
                const preview = event.target.closest('#item-preview--header').nextSibling;
                // const preview = event.target.parentNode.parentNode.nextSibling;
                const toggler = event.target;
                this.listLazyload.update();
                if (preview.classList.contains('opened')) {
                    preview.classList.remove('opened');
                    toggler.classList.remove('up');
                    toggler.classList.add('down');
                } else {
                    this.checkDropdowns(toggler, preview);
                }
            }
        }
    }

}
</script>