<template>
    <div>
        <div id="activity-preview--header">
            <router-link :to="{path: '/activities/' + activityContent.slug}">
                <div v-if="activityContent.photo_cover" class="activity-thumb" @click="togglePreview">
                    <img v-if="activityContent.photo_cover.formats.medium" :data-src="api_url + activityContent.photo_cover.formats.medium.url" :alt="activityContent.photo_cover.name" class="lazy">
                    <img v-else-if="activityContent.photo_cover.formats.small" :data-src="api_url + activityContent.photo_cover.formats.small.url" :alt="activityContent.photo_cover.name" class="lazy">
    
                    <div class="activity-btn-wrapper">
                        <span v-if="$i18n.locale === 'nb' && activityContent.activity_category" class="rounded-btn--green metatext">{{ activityContent.activity_category.category_no }}</span>
                        <span v-else-if="activityContent.activity_category" class="rounded-btn--green metatext">{{ activityContent.activity_category.category_en }}</span>
                    </div>
                </div>
            </router-link>
            
            <div v-if="isArchived(activityContent.date)" class="activity-meta metatext">
                <p v-if="activityContent.planned_events.length > 1">
                    <span v-if="$i18n.locale === 'nb'">
                        <span style="text-transform:capitalize;">{{ activityContent.planned_events[0].date | formatNBday }} </span>
                        <span>{{ activityContent.planned_events[0].date | formatDateFull }} Kl. {{ activityContent.planned_events[0].time_start | formatTime }}–{{ activityContent.planned_events[0].time_end | formatTime }} og {{ activityContent.planned_events.length - 1 }} {{ $t('activities.multipleActivities') }}</span>
                      </span>
                    <span v-else>
                      <span>{{ activityContent.planned_events[0].date | formatENday }} </span>
                      <span>{{ activityContent.planned_events[0].date | formatDateFull }}, {{ activityContent.planned_events[0].time_start | formatTime }}–{{ activityContent.planned_events[0].time_end | formatTime }} and {{ activityContent.planned_events.length - 1 }} {{ $t('activities.multipleActivities') }}</span>
                    </span>
                </p>
    
                <p v-else>
                    <span v-if="$i18n.locale === 'nb'">
                      <span style="text-transform:capitalize;">{{ activityContent.date | formatNBday }} </span>
                        <span>{{ activityContent.date | formatDateFull }} Kl. {{ activityContent.planned_events[0].time_start | formatTime }}–{{ activityContent.planned_events[0].time_end | formatTime }}</span>
                    </span>
                    <span v-else>
                      <span>{{ activityContent.date | formatENday }} </span>
                        <span>{{ activityContent.date | formatDateFull }}, {{ activityContent.planned_events[0].time_start | formatTime }}–{{ activityContent.planned_events[0].time_end | formatTime }}</span>
                    </span>

                </p>
            </div>
    
            <h3 class="activity-header">
                <p v-if="$i18n.locale === 'nb'" :class="[ activityContent.contentShort_no ? 'title-hover' : 'title-default']" @click="togglePreview">
                    <span>{{ activityContent.title_no }}<i
                v-if="activityContent.contentShort_no"
                class="arrow down"
              /></span>
                </p>
                <p v-else :class="[ activityContent.contentShort_no ? 'title-hover' : 'title-default']" @click="togglePreview">
                    <span>{{ activityContent.title_en }}<i
                v-if="activityContent.contentShort_en"
                class="arrow down"
              /></span>
                </p>
            </h3>
        </div>
    
        <div id="activity-preview--content" class="closed">
            <div v-if="activityContent.contentShort_no" id="activity-description">
                <MarkedParser v-if="$i18n.locale === 'nb'" class="markdown" :marked-content="activityContent.contentShort_no" />
                <MarkedParser v-else-if="activityContent.contentShort_en === '' || activityContent.contentShort_en === null" class="markdown" :marked-content="activityContent.contentShort_no" />
                <MarkedParser v-else class="markdown" :marked-content="activityContent.contentShort_en" />
            </div>
            <router-link :to="{path: '/activities/' + activityContent.slug}" class="square-btn--default">
                <span>{{ $t('buttons.moreInfo') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import MarkedParser from '../MarkedParser.vue'

import { format } from 'date-fns'
import LazyLoad from 'vanilla-lazyload'


export default {
    components: {
        MarkedParser
    },
    props: {
        activityContent: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            thisActivity: this.activity,
            activityDaY: '',
            api_url: process.env.VUE_APP_STRAPI_API_URL
        };
    },
    computed: {
        thisDay() {
            return format(new Date(), "dd.MM.yyyy")
        },
        today() {
            return format(new Date(), "dd.MM.yyyy HH:mm")
        },
        checkRegistration() {
            let thisDay = new Date()
            let actClosed = new Date(this.activityContent.reg_closed);
            let actStart = new Date(this.activityContent.date_start);
            if (thisDay < actClosed) {
                return true;
            } else if (thisDay > actStart) {
                return false;
            } else {
                return false;
            }
        },
        checkEN: function() {
            let textEN = this.activityContent.contentShort_en
            if (textEN === '' || textEN === null) {
                return true
            }
            return false
        },
    },
    mounted() {
        this.activityLazyload = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 500,
            threshold: 10,
        });
    },
    methods: {
        isArchived(date) {
            const day = format(new Date(), "yyyy-MM-dd")
            if (day > date) {
                return false
            } 
            return true 
        },
        togglePreview: function(event) {
            if (event && this.activityContent.contentShort_no) {
                const preview = event.target.closest('#activity-preview--header').nextSibling;
                const toggler = event.target.closest('#activity-preview--header').querySelector('i');
                if (preview.classList.contains('closed')) {
                    preview.classList.toggle('closed');
                    toggler.classList.remove('down');
                    toggler.classList.add('up');
                } else {
                    preview.classList.toggle('closed');
                    toggler.classList.remove('up');
                    toggler.classList.add('down');
                }
            }
        },
        getActivityDay() {
            let date = new Date();
            let datePlusDay = add(date, {days: -1}) 
            this.activityDay = datePlusDay
        },
    },
}
</script>