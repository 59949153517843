<template>
    <div v-if="adult_activities || child_activities" id="activities-overview" class="defaultcontent-grid--fullwidth">

        <div v-if="adult_activities.length > 0" id="adults-list">
            <h1 class="section-header">
                {{ $t('activities.adults') }}
                <span v-if="$route.path == '/'"><router-link :to="{ path: '/activities/adults' }" class="square-btn--default">{{ $t("buttons.otherActivities") }}</router-link></span>
            </h1>
            <ul v-if="adult_activities.length > 0" class="activity-grid--horizontal">
                <li v-for="activity in sortAdults" :key="activity.id" class="activity-block">
                    <ActivityBlock :activity-content="activity" />
                </li>
            </ul>
            <ul v-else class="activity-grid--horizontal">
                <li class="event-grey-out">
                    <span>{{ $t('activities.noevents') }}</span>
                </li>
            </ul>
        </div>

        <div v-if="child_activities.length > 0" id="children-list">
            <h1 class="section-header">
                {{ $t('activities.children') }}
                <span v-if="$route.path == '/'"><router-link :to="{ path: '/activities/children' }" class="square-btn--default">{{ $t("buttons.otherActivities") }}</router-link></span>
            </h1>
            <ul v-if="child_activities.length > 0" class="activity-grid--horizontal">
                <li v-for="activity in sortChildren" :key="activity.id" class="activity-block">
                    <ActivityBlock :activity-content="activity" />
                </li>
            </ul>
            <ul v-else class="activity-grid--horizontal">
                <li class="event-grey-out">
                    <span>{{ $t('activities.noevents') }}</span>
                </li>
            </ul>
        </div>
        
    </div>
</template>

<script>
import ActivityBlock from './ActivityBlock'

export default {
    components: {
        ActivityBlock
    },
    props: {
        activities: {
            type: Array,
            default: () => ([])
        },
        adult_activities: {
            type: Array,
            default: () => ([])
        },
        child_activities: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {

        sortAdults() {
            return this.processEvents(this.adult_activities)
        },
        sortChildren() {
            return this.processEvents(this.child_activities)
        }
    },
    methods: {
        processEvents(array) {
            let today = new Date();
            let yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1);

            let activities = array.map((e) => {
                function filterDate(item) {
                    let thisDate = new Date(item.date)
                    if (thisDate > yesterday) {
                        return true
                    }
                    return false;
                }
                let result = e.planned_events.filter(filterDate)
                e.planned_events = result
                return e
            })

            let sorted = activities.sort((a, b) => {
                return (new Date(a.planned_events[0].date) - new Date(b.planned_events[0].date));
            })

            return sorted;
        },
    }
};
</script>