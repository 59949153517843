<template>
  <div id="activities-overview" class="defaultcontent-grid--fullwidth">

    <section>
        <h1 class="section-header">{{ $t('activities.adults') }}</h1>

        <ul v-if="coming_activities.length > 0" class="activity-grid--horizontal">
            <li v-for="activity in sortCurrentAdults" :key="activity.id" class="activity-block">
                <ActivityBlock :activity-content="activity" />
            </li>
        </ul>
        
        <ul v-else class="activity-grid--horizontal">
            <li class="event-grey-out">
                <span>{{ $t('activities.noevents') }}</span>
            </li>
        </ul>
    </section>

    <section v-if="past_activities.length > 0">
        <h1 class="section-header">{{ $t('headers.archiveAdults') }}</h1>

        <ListComponent :list-content="sortArchiveAdults" />
        
        <div v-if="sortArchiveAdults.length < totalPastCount.length" id="loadmore">
            <button v-if="morePosts" class="square-btn--default" @click="loadMore">
                {{ $t('buttons.loadMoreActivities') }}
            </button>
        </div>

    </section>
  </div>
  
</template>

<script>
import ActivityBlock from '@/components/activities/ActivityBlock'
import ListComponent from '@/components/ListComponent'

import { add } from "date-fns";
import gql from "graphql-tag";

const getActivities = gql `
    query Activities($activityDay: String!, $start: Int!, $limit: Int!) {
        coming_activities: activities(start: $start, limit: $limit, where: { date_gt: $activityDay, programme: "Adults"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_no
            contentShort_en
            content_no
            content_en
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                date
                time_start
                time_end
            }
        }
        past_activities: activities(start: $start, limit: $limit, sort: "date:desc", where: { date_lte: $activityDay, programme: "Adults"} ) {
            slug
            programme
            title_en
            title_no
            date
            contentShort_no
            contentShort_en
            content_no
            content_en
            activity_category {
                category_en
                category_no
            }
            photo_cover {
                formats
            }
            planned_events {
                date
                time_start
                time_end
            }
        }
    }
`;

const getAllActivities = gql `
  query totalCount {
    totalCount: activities {
      id
    }
  }
`;

const getAllPastActivities = gql `
  query totalPastCount($activityDay:String!) {
    totalPastCount: activities(where: { date_lte: $activityDay, programme: "Adults"} ) {
      id
    }
  }
`;

export default {
    apollo: {
        coming_activities: {
            query: getActivities,
            variables() {
                return {
                    limit: -1,
                    start: 0,
                    activityDay: this.activityDay
                }
            }
        },
        past_activities: {
            query: getActivities,
            variables() {
                return {
                    limit: 6,
                    start: 0,
                    activityDay: this.activityDay
                }
            }
        },
        totalCount: {
            query: getAllActivities,
            result({ data }) {
                this.totalCount = data.totalCount
            },
        },
        totalPastCount: {
            query: getAllPastActivities,
            result({ data }) {
                this.totalPastCount = data.totalPastCount
            },
            variables() {
                return {
                    activityDay: this.activityDay
                }
            }
        },
    },
    components: {
        ActivityBlock,
        ListComponent
    },
    data () {
        return {
            coming_activities: [],
            past_activities: [],
            activityDay: '',
            totalCount: {},
            totalPastCount: {},
            amountPosts: null,
        }
    },
    computed: {
        morePosts() {
            return this.totalCount.length > this.past_activities.length
        },
        sortCurrentAdults() {
            return this.sortCurrent(this.coming_activities);
        },
        sortArchiveAdults() {
            return this.sortArchive(this.past_activities);
        }
    },
    created() {
        this.getActivityDay()
    },
    methods: {
        runSort(content) {
            let activities = content
            let today = new Date();
            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            
            activities = activities.map((e) => {
                function filterDate(item) {
                    let finalDate = new Date(item.date)
                    if (finalDate > yesterday) {
                        return true
                    }
                    return false;
                }
                let events = e.planned_events.filter(filterDate)
                e.planned_events = events
                return e
            })
            let sorted = activities.sort((a, b) => {
                if (a.planned_events.length > 0) {
                    return (new Date(a.planned_events[0].date) - new Date(b.planned_events[0].date));
                }
            })
            return sorted;
        },

        sortCurrent(content) {
            return this.runSort(content);
        },

        sortArchive(content) {
            return this.runSort(content);
        },

        getActivityDay() {
            let date = new Date();
            let datePlusDay = add(date, {days: -1}) 
            this.activityDay = datePlusDay
        },

        loadMore() {
            this.$apollo.queries.past_activities.fetchMore({
                variables: {
                    start: this.past_activities.length
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    return Object.assign({}, previousResult, {
                        past_activities: [...previousResult.past_activities, ...fetchMoreResult.past_activities]
                    })
                }
            })
        },
        
        metaInfo() {
            return {
                title: this.$t("nav.activities"),
            }
        }
    }
}
</script>