<template>
    <div v-if="photoCover" id="photo-cover-wrapper">
        <figure v-if="photoCover.formats.large && photoCover.formats.large.width > photoCover.formats.large.height" id="photo-cover" class="photo-cover--large-landscape">
            <img :src="api_url + photoCover.formats.large.url" alt="">
        </figure>
    
        <figure v-else-if="photoCover.formats.medium && photoCover.formats.medium.width > photoCover.formats.medium.height" id="photo-cover" class="photo-cover--medium-landscape">
            <img :src="api_url + photoCover.formats.medium.url" alt="">
        </figure>
    
        <figure v-else-if="photoCover.formats.small && photoCover.formats.small.width > photoCover.formats.small.height" id="photo-cover" class="photo-cover--small-landscape">
            <img :src="api_url + photoCover.formats.small.url" alt="">
        </figure>
    
        <figure v-else-if="photoCover.formats.large && photoCover.formats.large.width < photoCover.formats.large.height" id="photo-cover" class="photo-cover--large-portrait">
            <img :src="api_url + photoCover.formats.large.url" alt="">
        </figure>
    
        <figure v-else-if="photoCover.formats.medium && photoCover.formats.medium.width < photoCover.formats.medium.height" id="photo-cover" class="photo-cover--medium-portrait">
            <img :src="api_url + photoCover.formats.medium.url" alt="">
        </figure>
    
        <figure v-else-if="photoCover.formats.small && photoCover.formats.small.width < photoCover.formats.small.height" id="photo-cover" class="photo-cover--small-portrait">
            <img :src="api_url + photoCover.formats.small.url" alt="">
        </figure>
    
        <figure v-else id="photo-cover">
            <img :src="api_url + photoCover.formats.small.url" alt="">
        </figure>
    
        <figcaption v-if="photoCover.caption">
            {{ photoCover.caption }}
        </figcaption>
    </div>
</template>

<script>
export default {
    props: {
        photoCover: {
          type: Object,
          default: () => ({})
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        }
    }
}
</script>