<template>
  <button
    class="back-btn"
    @click="goBack"
  >
    {{ $t('buttons.back') }}
  </button>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    },
}
</script>